exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datalhln-index-js": () => import("./../../../src/pages/datalhln/index.js" /* webpackChunkName: "component---src-pages-datalhln-index-js" */),
  "component---src-pages-search-[id]-js": () => import("./../../../src/pages/search/[id].js" /* webpackChunkName: "component---src-pages-search-[id]-js" */),
  "component---src-pages-sertifikat-[id]-js": () => import("./../../../src/pages/sertifikat/[id].js" /* webpackChunkName: "component---src-pages-sertifikat-[id]-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-js": () => import("./../../../src/templates/article-list.js" /* webpackChunkName: "component---src-templates-article-list-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

